import React from "react"
import { Helmet } from "react-helmet"
import styles from "./component.module.css"
import TopheaderMenu from "./top-header-with-menu/topHeaderWithMenu"

const Header = props => {
  return (
    <header className={`${styles.header}  `}
    style= {{backgroundImage: `url(${props.headerImage})`} }
    >
      <Helmet>
        <meta name="google-site-verification" content="e85penxgkXL0dfVY5B6DP-QODFQbo_B6iOVMFDfO5PQ" />
        <meta name="facebook-domain-verification" content="za3d6u2zwu0u5o6o4d865ytj2jhbh8" />
      </Helmet>
      <TopheaderMenu 
      url = {props.url}
      page={"index"}/>
      <div className={styles.headerDivContainer}>
        <div className={styles.headerDiv}>
          {
            props.softHeadline && (
              <div className={`${styles.h1} ${styles.headlineDiv}`}
              dangerouslySetInnerHTML={{ __html: props.softHeadline }}
              >
              </div>
            )
          }
          {props.headlineText && !props.softHeadline && (
            <h1 className={styles.h1}>{props.headlineText}</h1>
          )}
        </div>
        <div className={styles.hrAndPContainer}>
          <hr />
          <div
            className={styles.p}
            dangerouslySetInnerHTML={{ __html: props.headlineSubText }}
          ></div>
        </div>
        <img
          className={styles.header3dimage}
          src={props.headerImage}
          alt="Digitalwert"
          style= {{display: 'none'}}
        />
      </div>
    </header>
  )
}

export default Header
