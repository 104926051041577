import React, { useState, useRef } from "react"
import window from "global"

import styles from "./component.module.css"

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

const AddedValue = props => {
  const [displayStrategie, setDisplayStrategie] = useState(true)
  const [displayTechnologie, setDisplayTechnologie] = useState(false)
  const [displayVermarktung, setDisplayVermarktung] = useState(false)
  const [isMobile, setMobile] = useState(window.innerWidth < 476)
  const myRef = useRef(null)

  let textAndList = (valueP, valueUl, clicked, desktop) => (
    <div className={desktop ? styles.textAndListContainer : styles.textAndList} style={clicked ? {} : {display: "none"}}>
      <div
        className={desktop ? "" : styles.middleItemUnderButton}
        dangerouslySetInnerHTML={{ __html: valueP }}
      ></div>
      <ul
        className={`${desktop ? "" : styles.middleItemUnderButton} ${
          displayTechnologie ? styles.link : ""
        }`}
        dangerouslySetInnerHTML={{ __html: valueUl }}
      ></ul>
    </div>
  )

  return (
    <div className={styles.container} ref={myRef}>
      <h2
        className={styles.h1}
        dangerouslySetInnerHTML={{ __html: props.headline }}
      >
        {/* Unsere Wertschöpfung */}
      </h2>
      <div className={styles.flexContainer}>
        <div className={styles.addedValuesContainer}>
          <button
            aria-label="info"
            onClick={() => {
              setDisplayStrategie(true)
              setDisplayTechnologie(false)
              setDisplayVermarktung(false)
              window.innerWidth < 476 ? scrollToRef(myRef) : setMobile(isMobile)
            }}
            className={`${displayStrategie ? styles.displayOn : ""} ${
              displayTechnologie ? styles.displayOf : ""
            }`}
          >
            <b>01 </b>
            {props.value1} <span className={styles.span01}></span>
          </button>
          {textAndList(props.value1P, props.value1Ul, displayStrategie, false)}

          <button
            aria-label="info"
            onClick={() => {
              setDisplayTechnologie(true)
              setDisplayStrategie(false)
              setDisplayVermarktung(false)
              window.innerWidth < 476 ? scrollToRef(myRef) : setMobile(isMobile)
            }}
            className={` ${displayTechnologie ? styles.displayOn : ""}`}
          >
            <b>02 </b>
            {props.value2}
            <span className={styles.span02}></span>
          </button>
          {textAndList(props.value2P, props.value2Ul, displayTechnologie, false)}

          <button
            aria-label="info"
            onClick={() => {
              setDisplayVermarktung(true)
              setDisplayTechnologie(false)
              setDisplayStrategie(false)
              window.innerWidth < 476 ? scrollToRef(myRef) : setMobile(isMobile)
            }}
            className={` ${displayVermarktung ? styles.displayOn : ""}`}
          >
            <b>03 </b>
            {props.value3}
            <span className={styles.span03}></span>
          </button>
          {textAndList(props.value3P, props.value3Ul, displayVermarktung, false)}

        </div>
        {textAndList(props.value3P, props.value3Ul, displayVermarktung, true)}
        {textAndList(props.value2P, props.value2Ul, displayTechnologie, true)}
        {textAndList(props.value1P, props.value1Ul, displayStrategie, true)}
      </div>
    </div>
  )
}

export default AddedValue
