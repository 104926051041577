import React from "react"
import styles from "./component.module.css"
import { graphql, Link, StaticQuery } from "gatsby"

import ShopwareImg from "../../images/img-news-shopware.png"

const News = props => {
  return (
    <StaticQuery
      query={graphql`
        query NewsDataQuery {
          allDatoCmsNews {
            nodes {
              id
              category
              title
              slug
              teasertext
              teaserimage {
                alt
                fluid(imgixParams: { auto: "compress" }) {
                  src
                }
              }
              teaserimagetitle
              providerPublicationDate
            }
          }
        }
      `}
      render={data => {
        let sortedNews = data.allDatoCmsNews.nodes.sort((a, b) => {
          return (
            new Date(b.providerPublicationDate) -
            new Date(a.providerPublicationDate)
          )
        })
        return (
          <div
            className={styles.newsContainer}
            style={
              props.page === "news-listing"
                ? {
                    marginTop: "10px",
                    marginBottom: "10px",
                  }
                : {}
            }
          >
            {props.page !== "news-listing" && (
              <a href="/news" className={styles.a}>
                Unsere News
              </a>
            )}
            <div className={styles.allPartsContainer}>
              {sortedNews.map(newsArticle => {
                let categoryClass = ""
                let categoryDescriptionClass = ""
                let categoryIconClass = ""
                switch (newsArticle.category) {
                  case "Shopware":
                    categoryClass = styles.shopware
                    categoryDescriptionClass = styles.shopwareDescription
                    categoryIconClass = styles.shopwareIcon
                    break
                  case "Apps":
                    categoryClass = styles.shopware
                    categoryDescriptionClass = styles.shopwareDescription
                    categoryIconClass = styles.crossPlatformIcon
                    break
                  case "Digitalwert":
                    categoryClass = styles.shopware
                    categoryDescriptionClass = styles.shopwareDescription
                    categoryIconClass = styles.digitalwertIcon
                    break
                  default:
                    categoryClass = styles.shopware
                    break
                }

                return (
                  <Link
                    key={newsArticle.id}
                    to={"/news/" + newsArticle.slug + "/"}
                    className={categoryClass}
                  >
                    <div className={categoryDescriptionClass}>
                      <h3 className={styles.h2}>
                        {newsArticle.title}
                        <span
                          aria-label="more info"
                          className={styles.more}
                        ></span>
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: newsArticle.teasertext,
                        }}
                      ></div>
                      <div className={categoryIconClass}>
                        <div></div>
                      </div>
                    </div>
                    {newsArticle.teaserimage && (
                      <img
                        className={styles.newsImg}
                        src={
                          newsArticle.teaserimage
                            ? newsArticle.teaserimage?.fluid?.src
                            : { ShopwareImg }
                        }
                        alt={newsArticle.teaserimage.alt}
                      />
                    )}
                  </Link>
                )
              })}
            </div>
          </div>
        )
      }}
    />
  )
}

export default News
