import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import imageSrc from './../lib/image-src'

import Header from "../components/header/header"
import AddedValue from "../components/added-value/added-value"
import OurProjects from "../components/our-projects/our-projects"
import Technologies from "../components/technologies/technologies"
import CompanyIntro from "../components/companyInsideIntroduction/companyInsideIntroduction"
import Innovation from "../components/innovation/innovation"
import Career from "../components/career/career"
import Footer from "../components/footer/footer"
import News from "../components/news/news"
import CookieConsent from "../components/cookie-consent"

import styles from "../styles/index.module.css"

export default function Home({ data, location }) {
  const cookieConsentRef = React.createRef();
  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <Helmet htmlAttributes={{ lang: "de" }} defer={false}>
        <title>{data.datoCmsSeiten.seo.title}</title>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/static/images/apple-touch-icon-180x180.png"
        ></link>
        <meta name="description" content={data.datoCmsSeiten.seo.description} />
      </Helmet>

      <Header
        url={location.href}
        softHeadline={
          data.datoCmsSeiten.header.length > 0
            ? data.datoCmsSeiten.header[0].softHeadline
            : "Wir entwickeln ihren nutzerzentrierten Service für eine digitale Zukunft"
        }
        headlineText={
          data.datoCmsSeiten.header.length > 0
            ? data.datoCmsSeiten.header[0].headline
            : "Wir entwickeln ihren nutzerzentrierten Service für eine digitale Zukunft"
        }
        headerImage={
          imageSrc(data.datoCmsSeiten.header[0].bild)
        }
        headlineSubText={
          data.datoCmsSeiten.header.length > 0
            ? data.datoCmsSeiten.header[0].text
            : "Agentur für digitale Wertschöpfung"
        }
      />
      <main className={styles.main}>
        {data.datoCmsSeiten.content.map((contentElement, index) => {
          switch (contentElement.internal.type) {
            case "DatoCmsQuote":
              return (
                <section key={index} className={styles.quoteSection}>
                  <div className={styles.quoteSectionDiv}>
                    {contentElement.berschrift && (
                      <h2
                        className={styles.quoteH1}
                        dangerouslySetInnerHTML={{
                          __html: contentElement.berschrift,
                        }}
                      ></h2>
                    )}
                    <blockquote
                      dangerouslySetInnerHTML={{
                        __html: contentElement.text,
                      }}
                    />
                    <span></span>
                  </div>
                </section>
              )
            case "DatoCmsAddedValue":
              return (
                <div key={index} >
                <>
                  <AddedValue
                    headline={contentElement.headline}
                    value1={contentElement.value1}
                    value1P={contentElement.value1Textarea}
                    value1Ul={contentElement.list}
                    value2={contentElement.value2}
                    value2P={contentElement.value2Textarea}
                    value2Ul={contentElement.value2List}
                    value3={contentElement.value3}
                    value3P={contentElement.value3Textarea}
                    value3Ul={contentElement.value3List}
                  />
                  <OurProjects />
                </>
                </div>
              )

            case "DatoCmsLogo":
              return (
                <div key={index} >
                <Technologies
                  logo1src={imageSrc(contentElement.technologies[0])}
                  logo1alt={contentElement.technologies[0]?.alt}
                  logo2src={imageSrc(contentElement.technologies[1])}
                  logo2alt={contentElement.technologies[1]?.alt}
                  logo3src={imageSrc(contentElement.technologies[2])}
                  logo3alt={contentElement.technologies[2]?.alt}
                  logo4src={imageSrc(contentElement.technologies[3])}
                  logo4alt={contentElement.technologies[3]?.alt}
                  logo5src={imageSrc(contentElement.technologies[4])}
                  logo5alt={contentElement.technologies[4]?.alt}
                />
                </div>
              )

            case "DatoCmsCompanyIntroFirstVideoPart":
              return (
                <div key={index} >
                <CompanyIntro
                  videoImage={imageSrc(contentElement.backgroundOfVideo)}
                  videoUrl={contentElement.video.url}
                  videoHeadlineText={contentElement.videoHeadline}
                  videoSubHeadlineText={contentElement.videoParaghraph}
                  videoButtonText={contentElement.videoButtonText}
                  videoButtonUrl={contentElement.videoButtonUrl}
                  text={contentElement.text}
                  employeeImg1={imageSrc(contentElement.image1)}
                  employeeImg2={imageSrc(contentElement.image2)}
                />
                </div>
              )

            case "DatoCmsCtaBlue":
              return (
                <div key={index} >
                <Innovation
                  innovationHeadlineText={contentElement.headline}
                  innovationSubHeadlineText={contentElement.subheadline}
                  innovationButtonText={contentElement.buttonText}
                  innovationButtonUrl={contentElement.buttonUrl}
                  bg = {imageSrc(contentElement.background)}
                />
                </div>
              )

            case "DatoCmsAufgabenstellung":
              return (
                <div key={index} >
                <Career
                  careerHeadline={contentElement.berschriftH2}
                  careerP={contentElement.text}
                  list={contentElement.liste}
                />
                </div>
              )
            default:
              return <div key={index}></div>
          }
        })}
        <News />
      </main>
      <Footer cookieConsentRef={cookieConsentRef}/>
    </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    datoCmsSeiten(id: { eq: "DatoCmsSeiten-5790055-de" }) {
      seo {
        description
        title
        twitterCard
      }
      id
      name
      header {
        ... on DatoCmsHeaderTextBild {
          id
          bild {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
              base64
            }
            url
          }
          headline
          text
          softHeadline
        }
      }
      content {
        ... on DatoCmsCtaBlue {
          id
          buttonText
          headline
          subheadline
          buttonUrl
          internal {
            type
          }
          background {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
        }
        ... on DatoCmsText {
          id
          text
          quoteHeadline
          internal {
            type
          }
        }
        ... on DatoCmsVideofullwidth {
          id
          vorschaubild {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
              base64
            }
            url
          }
          videomp4 {
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsCompanyIntroFirstVideoPart {
          id
          backgroundOfVideo {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          videoHeadline
          videoParaghraph
          videoButtonText
          videoButtonUrl
          video {
            url
            video {
              mp4Url
            }
          }
          text
          image1 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          image2 {
            alt
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsLogo {
          id
          technologies {
            fluid(imgixParams: { auto: "compress" }) {
              src
            }
            url
            alt
          }
          internal {
            type
          }
        }
        ... on DatoCmsAddedValue {
          id
          headline
          value1
          value1Textarea
          list
          value2
          value2List
          value2Textarea
          value3
          value3Textarea
          value3List
          internal {
            type
          }
        }
        ... on DatoCmsQuote {
          id
          berschrift
          text
          internal {
            type
          }
        }
        ... on DatoCmsAufgabenstellung {
          id
          berschriftH2
          text
          liste
          internal {
            type
          }
        }
      }
    }
  }
`
