import React from 'react'
import styles from './compoent.module.css'

const technologies = (props) => {
    return (
        <div className={styles.techSection}>
        <div className={styles.techContainer}>
            <img src={props.logo1src} alt={props.logo1alt} />
            <img className={styles.typo3} src={props.logo2src} alt={props.logo2alt} />
            <img className={styles.ionic} src={props.logo3src} alt={props.logo3alt} />
            <img className={styles.datoCMS} src={props.logo4src} alt={props.logo4alt} />
            <img src={props.logo5src} alt={props.logo5alt} />
        </div>
        </div>
    )
}

export default technologies;