import React from 'react'
import styles from './companyInIntro.module.css'

const companyInsideIntro = (props) => {
    return (
        <div>
        <div className={styles.companyIntroSectionPart1}>
            <div className={styles.container}>
                {/* eslint-disable jsx-a11y/media-has-caption */}
                <video
                    controls={true}
                    poster={props.videoImage}
                    data-setup="{}"
                    loop={true}
                >
                    <source src={props.videoUrl} type="video/mp4" />
                </video>
                <div>
                    <span className={styles.line}></span>
                    <h2 className={styles.h2} dangerouslySetInnerHTML={{ __html: props.videoHeadlineText }}>
                    </h2>
                    <p>
                        {props.videoSubHeadlineText}
                    </p>
                    <a href={props.videoButtonUrl} className={styles.button}>
                        {props.videoButtonText}
                    </a>
                </div>
            </div>
            <div className={styles.dots}></div>
        </div>
            <div className={styles.companyIntroSectionPart2}>
                <div className={styles.companyIntroSecPart2Div}>
                <div className={styles.p} >
                    <p>{props.text}</p> 
                </div>
                <img className={styles.employeesImg1} src={props.employeeImg2} alt="Employees" />
                </div>
                <img className={styles.employeesImg2} src={props.employeeImg1} alt="Employees" />
            </div>

        </div>
    )
}

export default companyInsideIntro;
