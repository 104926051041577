import React from "react"
import styles from "./component.module.css"

const career = props => {
  return (
    <div className={styles.careerSection}>
      {props.page !== "jobs" && (
        <h2
          className={styles.h1}
          dangerouslySetInnerHTML={{ __html: props.careerHeadline }}
        >
        </h2>
      )}
      {props.page === "jobs" && (
        <h2
          className={styles.h1}
          dangerouslySetInnerHTML={{ __html: props.headline }}
        >
        </h2>
      )}
      <div className={styles.container}>
        {props.page !== "jobs" && (
          <div dangerouslySetInnerHTML={{ __html: props.careerP }}>
          </div>
        )}
        {props.page === "jobs" && 
          <div dangerouslySetInnerHTML={{ __html: props.text }}>
          </div>
        }
        {props.list &&
        <div dangerouslySetInnerHTML={{ __html: props.list }}>
        </div>
        }
      </div>
    </div>
  )
}

export default career
