import React from 'react'
import styles from './component.module.css'

const innovation = (props) => {
    return (
    <div className={styles.innovationSection} style={props.bg ? {backgroundImage: `url(${props.bg})` } : {}}>
        <div className={styles.container}>
            <div>
                <h2 className={styles.h1} dangerouslySetInnerHTML={{ __html: props.innovationHeadlineText }}>
                </h2>
                <p>
                    {props.innovationSubHeadlineText}
                </p>
            </div>
            <a href={props.innovationButtonUrl} className={styles.button}>
                {props.innovationButtonText}
            </a>
        </div>
    </div>
    )
}

export default innovation;